<template>
  <auth-layout>
    <template v-slot:title>
      <span>{{ $t("auth_form_login_title") }}</span>
    </template>
    <template v-slot:subtitle>
      <span>• {{ $t("auth_form_login_subline_1") }}</span>
      <span>• {{ $t("auth_form_login_subline_2") }}</span>
      <span>• {{ $t("auth_form_login_subline_3") }}</span>
    </template>
    <template v-slot:form>
      <v-form
        v-if="!showSignInOptions"
        ref="form"
        class="login-form"
        lazy-validation
        @submit.prevent="checkUser"
      >
        <v-text-field
          class="form-input"
          v-model="email"
          :label="$t('email')"
          :rules="[(val) => rules.email(val) || $t('auth_form_invalid_email')]"
          validate-on-blur
          id="email"
          type="email"
          data-testid="email"
          ref="email"
        />
        <div class="form-buttons">
          <mem-button
            class="continue-button"
            btnType="secondary-dark"
            :disabled="disableBtn"
            :loading="loading"
            type="submit"
          >
            {{ $t("continue") }}
          </mem-button>

          <div class="return-link" @click="returnToAllOptions">
            <i class="material-icons link-icon">chevron_left</i>
            <span class="link-title">{{ $t("all_sign_up_options") }}</span>
          </div>
        </div>
      </v-form>
      <div v-else class="sign-in-options">
        <apple-auth-button
          class="auth-button"
          :redirect-url="redirectUrl"
          :text="$t('continue_with_apple')"
        />
        <facebook-button
          class="auth-button"
          :redirect-url="redirectUrl"
          :text="$t('continue_with_facebook')"
        ></facebook-button>
        <google-sign-in-button
          v-if="!isWebView"
          class="auth-button"
          :redirect-url="redirectUrl"
          :text="$t('continue_with_google')"
        />
        <mem-button
          class="auth-button sign-in-button"
          btnType="tertiary-dark"
          @click="signInEmail"
        >
          <i class="material-icons button-icon">email</i>
          <span class="button-title">
            {{ $t("continue_with_email") }}
          </span>
        </mem-button>
      </div>
      <form-terms class="login-form-terms" />
    </template>
  </auth-layout>
</template>
<script>
import { emailValidator } from "@/common/validators";
import { mapActions } from "vuex";
import deviceInfoMixin from "./deviceInfoMixin";
import mixinAuthForms from "./mixinAuthForms";
import GoogleSignInButton from "@/components/GoogleSignInButton";

export default {
  name: "FormLogin",
  data() {
    return {
      email: "",
      rules: {
        email: emailValidator,
      },
      loading: false,
      showSignInOptions: true,
    };
  },
  mixins: [deviceInfoMixin, mixinAuthForms],
  components: {
    GoogleSignInButton,
    "auth-layout": () => import("./AuthLayout.vue"),
    "mem-button": () => import("../../components/base/BaseButton.vue"),
    "facebook-button": () => import("@/components/ButtonFacebook.vue"),
    "apple-auth-button": () => import("@/components/ButtonAppleAuth.vue"),
    "form-terms": () => import("./TermsSection.vue"),
  },
  computed: {
    disableBtn() {
      return !emailValidator(this.email);
    },
  },
  methods: {
    ...mapActions(["checkUserExist"]),
    async checkUser() {
      try {
        this.loading = true;
        let user = await this.checkUserExist(this.email);
        if (user.exists)
          this.$router.push({
            name: "formSignIn",
            params: { email: this.email },
            query: this.$route.query,
          });
        else
          this.$router.push({
            name: "formSignUp",
            params: { email: this.email },
            query: this.$route.query,
          });
      } catch (err) {
        console.log("check user error", err);
        this.loading = false;
      }
    },
    returnToAllOptions() {
      this.showSignInOptions = true;
    },
    signInEmail() {
      this.showSignInOptions = false;
      this.$nextTick(() => {
        new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 300);
        }).then(() => {
          if (this.$refs.email) {
            this.$refs.email.focus();
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.login-form {
  // max-width: 347px;
  // width: 100%;

  .form-buttons {
    margin-top: 40px;
    @media screen and (max-width: 499px) {
      margin-top: 0;
    }

    .continue-button {
      width: 100%;
      height: 50px;
      border-radius: 30px;
    }

    .return-link {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 46px;
      color: #aeaeae;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      text-transform: uppercase;

      .link-icon {
        font-size: 20px;
        & + .link-title {
          margin-left: 4px;
        }
      }
      &:hover {
        color: white;
        cursor: pointer;
      }
    }
  }
}

.sign-in-options {
  display: flex;
  flex-direction: column;

  .sign-in-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    text-transform: uppercase;
    height: 50px;
    border-radius: 30px;

    .button-icon {
      position: absolute;
      left: 15px;
      font-size: 20px;
      top: calc(50% - 10px);
    }
  }
  .auth-button {
    position: relative;
    & + .auth-button {
      margin-top: 12px;
    }
  }
}
.login-form-terms {
  margin-top: 32px;
  text-align: center;
}
</style>
